/**
 * @generated SignedSource<<9e6f1330eb9f8223c8c66717aebe28c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePaginatedDataFragment_pageInfo$data = {
  readonly endCursor: string;
  readonly hasNextPage: boolean;
  readonly " $fragmentType": "usePaginatedDataFragment_pageInfo";
};
export type usePaginatedDataFragment_pageInfo$key = {
  readonly " $data"?: usePaginatedDataFragment_pageInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedDataFragment_pageInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePaginatedDataFragment_pageInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "type": "PageInfo",
  "abstractKey": null
};

(node as any).hash = "4d55678cfc7914b77c689dce2df7dd2d";

export default node;
